<script setup>
import PrimaryButton from "@/Components/PrimaryButton.vue";
import InputError from "@/Form/InputError.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import Card from "primevue/card";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import ToggleSwitch from "primevue/toggleswitch";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};
</script>

<template>
    <Head title="Log in" />
    <div id="login-wrapper" class="">
        <div
            class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100 dark:bg-gray-900"
        >
            <Card style="overflow: hidden">
                <template #header>
                    <Link href="/" class="flex flex-row items-center p-4">
                        <img src="/static/images/logo.png" class="h-24" />
                    </Link>
                </template>
                <template #content>
                    <div
                        v-if="status"
                        class="mb-4 font-medium text-sm text-green-600 dark:text-green-400"
                    >
                        {{ status }}
                    </div>
                    <form @submit.prevent="submit">
                        <div>
                            <FloatLabel>
                                <InputText
                                    id="email"
                                    v-model="form.email"
                                    type="email"
                                    class="mt-1 block w-full"
                                    required
                                    autofocus
                                    autocomplete="username"
                                />
                                <label for="email">Email</label>
                            </FloatLabel>

                            <InputError
                                class="mt-2"
                                :message="form.errors.email"
                            />
                        </div>
                        <div>
                            <FloatLabel>
                                <InputText
                                    id="password"
                                    v-model="form.password"
                                    type="password"
                                    class="mt-1 block w-full"
                                    required
                                    autocomplete="current-password"
                                />
                                <label for="password">password</label>
                            </FloatLabel>
                            <InputError
                                class="mt-2"
                                :message="form.errors.password"
                            />
                        </div>
                        <!--
                        <div>
                            <InputLabel for="email" value="Email"/>
                            <TextInput
                                id="email"
                                v-model="form.email"
                                type="email"
                                class="mt-1 block w-full"
                                required
                                autofocus
                                autocomplete="username"
                            />
                            <InputError class="mt-2" :message="form.errors.email"/>
                        </div>

                        <div class="mt-4">
                            <InputLabel for="password" value="Password"/>
                            <TextInput
                                id="password"
                                v-model="form.password"
                                type="password"
                                class="mt-1 block w-full"
                                required
                                autocomplete="current-password"
                            />
                            <InputError class="mt-2" :message="form.errors.password"/>
                        </div>
-->
                        <div class="block mt-4">
                            <label class="flex items-center">
                                <ToggleSwitch
                                    v-model="form.remember"
                                    name="remember"
                                />

                                <span
                                    class="ms-2 text-sm text-gray-600 dark:text-gray-400"
                                    >Remember me</span
                                >
                            </label>
                        </div>

                        <div class="flex items-center justify-end mt-4">
                            <Link
                                v-if="canResetPassword"
                                :href="route('password.request')"
                                class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
                            >
                                Forgot your password?
                            </Link>

                            <PrimaryButton
                                class="ms-4"
                                :class="{ 'opacity-25': form.processing }"
                                :disabled="form.processing"
                            >
                                Log in
                            </PrimaryButton>
                        </div>
                    </form>
                </template>
            </Card>
        </div>
    </div>
    <!--
    <div class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100 dark:bg-gray-900">
        <div
            class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white dark:bg-gray-800 shadow-md overflow-hidden sm:rounded-lg">



        </div>
    </div>
--></template>
